import { Link } from 'gatsby'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import Nav from '../components/Nav'

const Product = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const pic_hdg_top = '../assets/images/hdg/hdg_top_img.jpg'
  const pic_ddg_top = '../assets/images/ddg/ddg_top_img.jpg'
  const pic_edg_top = '../assets/images/edg/edg_top_img.jpg'
  const pic_utsushiyo_top = '../assets/images/utsushiyo/utsushiyo_top_img.jpg'
  const pic_otapri_top = '../assets/images/otapri/otapri_top_img.jpg'
  const pic_loveescape_top = '../assets/images/loveescape/loveescape_top_img.jpg'
  const pic_xd_top = '../assets/images/xd/xd_top_img.png'
  const pic_dap_top = '../assets/images/dap/dap_top_img.png'

  return (
    <Layout>
      <Helmet title="Product" />
      <HeaderGeneric />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="product" className="main special">
          <header className="major">
            <h2>Product</h2>
          </header>
          <ul className="features">
            <li>
              <span className="image mafor"><Link to="/product/hope-detective-girl"><StaticImage src={pic_hdg_top} alt="" /></Link></span>
              <h3>希望探偵少女 (小説)</h3>
            </li>
            <li>
              <span className="image major"><Link to="/product/death-detective-girl"><StaticImage src={pic_ddg_top} alt="" /></Link></span>
              <h3>死神探偵少女</h3>
            </li>
            <li>
            <span className="image major"><Link to="/product/escape-detective-girl"><StaticImage src={pic_edg_top} alt="" /></Link></span>
              <h3>脱出探偵少女</h3>
            </li>
          </ul>
          <ul className="features">
            <li>
              <span className="image mafor"><Link to="/product/utsushiyo"><StaticImage src={pic_utsushiyo_top} alt="" /></Link></span>
              <h3>脱出ゲーム うつしよ</h3>
            </li>
            <li>
              <span className="image major"><Link to="/product/otapri"><StaticImage src={pic_otapri_top} alt="" /></Link></span>
              <h3>おたのプリンセス！</h3>
            </li>
            <li>
            <span className="image major"><Link to="/product/loveescape"><StaticImage src={pic_loveescape_top} alt="" /></Link></span>
              <h3>愛されるより逃れたい</h3>
            </li>
          </ul>
          <ul className="features">
            <li>
              <span className="image mafor"><Link to="/product/xd"><StaticImage src={pic_xd_top} alt="" /></Link></span>
              <h3>CROSS DIMENSION</h3>
            </li>
            <li>
              <span className="image major"><Link to="/product/dap"><StaticImage src={pic_dap_top} alt="" /></Link></span>
              <h3>Dot Anime Player</h3>
            </li>
            <li>
            <span className="image major"></span>
            </li>
          </ul>
        </section>
        <section id="product" className="main special">
          <header className="major">
            <h2>Others</h2>
          </header>
            <ul className="features">
              <li>
                <span className="image major">
                  <a href="https://www.amazon.co.jp/dp/4777519996/?&_encoding=UTF8&tag=cretia-22&linkCode=ur2&linkId=077519845ebd4099f3b122571a247c68&camp=247&creative=1211" target="_blank" rel="noopener noreferrer">
                    <img src="https://images-fe.ssl-images-amazon.com/images/P/4777519996.09.MZZZZZZZZ" alt="" />
                  </a>
                </span>
                <h4>CocosCreatorではじめるゲーム制作</h4>
              </li>
            </ul>
          </section>
      </div>
    </Layout>
  )
}

export default Product
